import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Lottie from "react-lottie";
import * as NotFoundAnimation from "../animations/404animation.json";
import Seo from "../components/seo";
const NotFoundPage = () => {
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: NotFoundAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <Layout>
            <Seo title="Page not found" />
            <div className="container notfound">
                <div className="notfound-animation">
                    <span>4</span>
                    <Lottie options={lottieOptions} />
                    <span>4</span>
                </div>
                <div className="text-h2 text-fw-medium text-clr-primary gap-05x">
                    Oops. You've left the Sedin multiverse.
                </div>
                <div className="text-p2 text-fw-regular text-clr-secondary gap-05x">
                    Well, this is awkward. Let's take you back and try again.
                </div>
                <Link to="/" className="sdn-cta-btn">
                    Go Back to Home
                </Link>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
